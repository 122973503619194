// App.js
import React from 'react';
import NavBar from './components/NavBar';
import SuperHome from './components/SuperHome';
import Services from './components/Services';
import Oferts from './components/Oferts';
import Contact from './components/Contact';
import Faqs from './components/Faqs';
import BlurryBackground from './components/BlurryBackground';
import Testimonials from './components/Testimonials';
import PricingBox from './components/PricingBox';


function App() {
  return (
    <div>
      <BlurryBackground />
      <NavBar />
      <SuperHome />
      <Services />
      <Testimonials />
     
      <Contact />
      <Faqs />
    </div>
  );
}

export default App;

